import insuranceCategoryTypes from "@/enums/insuranceCategoryTypes";
import statusQuotationTypes from "@/enums/statusQuotationTypes";
import reasonCancelTypes from "@/enums/reasonCancelTypes";

export default {
  data: () => ({
    insuranceCategoryTypes: insuranceCategoryTypes,
    statusQuotationTypes: statusQuotationTypes,
    reasonCancelTypes: reasonCancelTypes,
  }),
  methods: {
    getEnumKeyByEnumValue(myEnum, enumValue) {
      let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
      return keys.length > 0 ? keys[0] : null;
    },
    openLinkTargetBlank(url) {
      window.open(
        url,
        '_blank'
      );
    },
    allowedJudicial() {
      return [
        this.insuranceCategoryTypes.MAKE_A_LABOR_APPEAL_DEPOSIT,
        this.insuranceCategoryTypes.ENSURE_A_LABOR_EXECUTION,
        this.insuranceCategoryTypes.I_HAVE_TAX_CREDIT_OR_INSTALLMENT,
        this.insuranceCategoryTypes.TAX_ENFORCEMENT,
        this.insuranceCategoryTypes.APPEAL_A_CIVIL_ACTION,
        this.insuranceCategoryTypes.REPLACEMENT_OF_APPEAL_DEPOSIT,
      ]
    },
    allowedCustoms() {
      return [
        this.insuranceCategoryTypes.CUSTOMS,
        this.insuranceCategoryTypes.CUSTOMS_TRANSIT,
        this.insuranceCategoryTypes.CUSTOMS_TEMPORARY_ADMISSION,
        this.insuranceCategoryTypes.ENERGY_PAYMENT,
      ]
    },
    listStatusKanbanQuotation() {
      return [
        this.statusQuotationTypes.ANALYSIS,
        this.statusQuotationTypes.APPROVED,
        this.statusQuotationTypes.ISSUE,
        this.statusQuotationTypes.ISSUED,
        this.statusQuotationTypes.DECLINED,
        this.statusQuotationTypes.ISSUE_FAILURE,
        this.statusQuotationTypes.ACTIVE,
        this.statusQuotationTypes.CANCELED,
        this.statusQuotationTypes.ENDORSED,
      ];
    },
    listStatusKanbanEndorsement() {
      return [
        this.statusQuotationTypes.PROGRESS,
        this.statusQuotationTypes.PENDING,
        this.statusQuotationTypes.RESOLVED,
      ];
    },
    listStatusKanbanPolicyRenewal() {
      return [
        this.statusQuotationTypes.AWAITING_DOCUMENTATION,
        this.statusQuotationTypes.AWAITING_APPROVAL,
        this.statusQuotationTypes.FINISHED,
        this.statusQuotationTypes.CANCELED
      ];
    },
    listReasonCancel() {
      return [
        this.reasonCancelTypes.PROCESS_FINALIZED,
        this.reasonCancelTypes.NO_RETURN,
        this.reasonCancelTypes.RENEWED_WITH_ANOTHER_BROKER,
        this.reasonCancelTypes.OTHERS
      ];
    },
    listStatusQuotationChange() {
      return [
        this.statusQuotationTypes.DRAFT,
        this.statusQuotationTypes.ANALYSIS,
        this.statusQuotationTypes.APPROVED,
        this.statusQuotationTypes.DECLINED,
        this.statusQuotationTypes.ISSUE_FAILURE,
      ];
    },
    classStatusQuotation(id) {
      let classStatus
      switch (id) {
        case 1:
        case 11:
          classStatus = 'btn btn-sm btn-outline-secondary text-uppercase rounded-pill nowrap';
          break;
        case 2:
          classStatus = 'btn btn-sm btn-primary text-uppercase rounded-pill nowrap';
          break;
        case 3:
        case 12:
        case 13:
          classStatus = 'btn btn-sm btn-orange text-white text-uppercase rounded-pill nowrap';
          break;
        case 4:
          classStatus = 'btn btn-sm btn-outline-success text-uppercase rounded-pill nowrap';
          break;
        case 5:
        case 8:
        case 14:
          classStatus = 'btn btn-sm btn-success text-uppercase rounded-pill nowrap';
          break;
        case 6:
        case 7:
        case 9:
          classStatus = 'btn btn-sm btn-danger text-uppercase rounded-pill nowrap';
          break;
        case 10:
          classStatus = 'btn btn-sm btn-primary-opmitize text-uppercase rounded-pill nowrap';
          break;
        case 15:
        case 18:
          classStatus = 'btn btn-sm btn-orange text-uppercase rounded-pill nowrap';
          break;
        default:
          classStatus = 'btn btn-sm btn-outline-secondary text-uppercase rounded-pill nowrap';
      }
      return classStatus
    },
    traitCnpj(cnpj = '') {
      return cnpj.replace(/[^\d]+/g, "");
    },
    formatCnpj(cnpj = '') {
      return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    },
    getIdFromUrl() {
      const url = window.location.href;
      return url.split("/").slice(-1)[0];
    }
  }
}
